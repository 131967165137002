
@font-face {
    font-family: xmfont;
    src: url(https://static.jzmbti.com/resume/iconfont.559e6b5f.ttf) format("truetype")
}

.xmfont {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: xmfont!important;
    font-size: 16px;
    font-style: normal
}

.xmicon-project:before {
    content: "\e612"
}

.xmicon-email:before {
    content: "\e667"
}

.xmicon-joinTime:before {
    content: "\e714"
}

.xmicon-birthday-age:before {
    content: "\eaec"
}

.xmicon-political:before {
    content: "\eb4f"
}

.xmicon-nativePlace:before {
    content: "\eb5e"
}

.xmicon-nationality:before {
    content: "\eb5f"
}

.xmicon-phone:before {
    content: "\eb77"
}

.xmicon-maritalStatus:before {
    content: "\eba2"
}

.xmicon-weight-height:before {
    content: "\ebbb"
}

.xmicon-name:before {
    content: "\ebbf"
}

.xmicon-user-graduate:before {
    content: "\ebc2"
}

.xmicon-gender:before {
    content: "\ebc8"
}

.xmicon-workAge:before {
    content: "\e611"
}

.xmicon-internship:before {
    content: "\e632"
}

.xmicon-salary:before {
    content: "\e63d"
}

.xmicon-extra:before {
    content: "\e65b"
}

.xmicon-photo:before {
    content: "\e6df"
}

.xmicon-post:before {
    content: "\e631"
}

.xmicon-liner-birthday:before {
    content: "\e63a"
}

.xmicon-liner-name:before {
    content: "\e63e"
}

.xmicon-liner-phone:before {
    content: "\e638"
}

.xmicon-liner-workAge:before {
    content: "\e79c"
}

.xmicon-liner-height-weight:before {
    content: "\e600"
}

.xmicon-liner-nativePlace:before {
    content: "\e607"
}

.xmicon-liner-maritalStatus:before {
    content: "\e617"
}

.xmicon-fenqixueli:before {
    content: "\e61b"
}

.xmicon-liner-political:before {
    content: "\e70f"
}

.xmicon-liner-nationality:before {
    content: "\e635"
}

.xmicon-liner-email:before {
    content: "\e601"
}

.xmicon-liner-salary:before {
    content: "\e7ae"
}

.xmicon-liner-joinTime:before {
    content: "\e8c4"
}

.xmicon-liner-gender:before {
    content: "\f0a5"
}

.xmicon-liner-photo:before {
    content: "\e6b9"
}

.xmicon-liner-extra:before {
    content: "\e634"
}

.xmicon-liner-post:before {
    content: "\e6ef"
}

.xmicon-evaluation:before {
    content: "\e630"
}

.xmicon-basic:before {
    content: "\eb29"
}

.xmicon-hobby:before {
    content: "\e60f"
}

.xmicon-cert:before {
    content: "\e99e"
}

.xmicon-school:before {
    content: "\e9df"
}

.xmicon-skill:before {
    content: "\e693"
}

.xmicon-job:before {
    content: "\e976"
}

.xmicon-customize:before {
    content: "\e60e"
}

.xmicon-xiangji:before {
    content: "\e86c"
}

.xmicon-yidong:before {
    content: "\e6c0"
}

.xmicon-duigou:before {
    content: "\e6c4"
}

.xmicon-guanbi-danchuang:before {
    content: "\e6c8"
}

.xmicon-tianjia:before {
    content: "\e6cf"
}

.xmicon-shangchuanzhaopian:before {
    content: "\e6d1"
}

.xmicon-shanchu:before {
    content: "\e6d4"
}

.xmicon-xiazai:before {
    content: "\e6d6"
}

.xmicon-jiantou-xia:before {
    content: "\ea10"
}

.xmicon-jiantou-up:before {
    content: "\ea11"
}

.xmicon-fontsize:before {
    content: "\e618"
}

.xmicon-changetpl:before {
    content: "\e639"
}

.xmicon-rowspace:before {
    content: "\e65f"
}

.xmicon-cover:before {
    content: "\e675"
}

.xmicon-skin:before {
    content: "\e7bb"
}

.xmicon-font:before {
    content: "\e68d"
}

.xmicon-work:before {
    content: "\f0a6"
}

.xmicon-education:before {
    content: "\e6c9"
}