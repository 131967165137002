.login-modal {
    padding: 24px;
    position: relative;
    .icon {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 69px;
        height: 69px;
        img {
            width: 41px;
            height: 41px;
        }
    }
    h3 {
        width: 98px;
        height: 24px;
        margin: 0 auto;
        margin-bottom: 24px;
        margin-top: 10px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .form {
        .input-item {
            position: relative;
            box-sizing: border-box;
            margin-bottom: 14px;
            input {
                padding: 9px 12px;
                font-size: 13px !important;
                outline: none;
                border: 1px solid #e9e9e9;
                width: 100%;
                box-sizing: border-box;
                line-height: 1 !important;
                height: auto !important;
            }
            .code {
                font-size: 13px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
            }
        }
    }
    .jz-btn {
        font-size: 13px;
        background: #00bca6;
        border-radius: 4px;
        color: #fff;
        text-align: center;
        padding: 9px 0;
        margin-bottom: 14px;
    }
    .other {
        p {
            font-size: 10px;
            color: #a1a1a1;
            margin-bottom: 6px;
        }
        .tip {
            color: #00bca6;
        }
        .center {
            text-align: center;
        }
    }
}
