.top-layout {
    position: relative;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    .top-title {
        font-size: 16px;
        width: 352px;
        text-align: center;
    }
    .back {
        left: 24px;
        position: absolute;

    }
    // margin-bottom: 10px;
}
