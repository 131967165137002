.resume-module {
    position: relative;
    /* overflow: hidden; */
}

.resume-module:hover {

}

.resume-field-highlight{
    background: rgba(17,187,166,0.1)!important;
    outline: 1px solid #5DB7AC;
    box-sizing: border-box;
}

.resume-module-border {
    border: 1px solid transparent;
    box-sizing: border-box;
}
.resume-module-active {
    background-color: #e6f8f6 !important;
    border: 1px dashed #5DB7AC;
}


.wbdCv-baseStyle[data_font_name="Aileron-Regular"], .wbdCv-baseStyle[data_font_name="Aileron-Regular"] * {
    font-family: Aileron-Regular;
}
.wbdCv-baseStyle[data_font_name="Times New Roman"], .wbdCv-baseStyle[data_font_name="Times New Roman"] * {
    font-family: Times New Roman;
}
.wbdCv-baseStyle[data_font_name="方正楷体简体"], .wbdCv-baseStyle[data_font_name="方正楷体简体"] * {
    font-family: 方正楷体简体;
}
.wbdCv-baseStyle[data_font_name="方正书宋简体"], .wbdCv-baseStyle[data_font_name="方正书宋简体"] * {
    font-family: 方正书宋简体;
}
.wbdCv-baseStyle[data_font_name="仿宋_GB2312"], .wbdCv-baseStyle[data_font_name="仿宋_GB2312"] * {
    font-family: 仿宋_GB2312;
}
.wbdCv-baseStyle[data_font_name="黑体"], .wbdCv-baseStyle[data_font_name="黑体"] * {
    font-family: 黑体;
}
.wbdCv-baseStyle[data_font_name="楷体"], .wbdCv-baseStyle[data_font_name="楷体"] * {
    font-family: 楷体;
}
.wbdCv-baseStyle[data_font_name="隶书"], .wbdCv-baseStyle[data_font_name="隶书"] * {
    font-family: 隶书;
}
.wbdCv-baseStyle[data_font_name="苹方黑体-简体"], .wbdCv-baseStyle[data_font_name="苹方黑体-简体"] * {
    font-family: 苹方黑体-简体;
}
.wbdCv-baseStyle[data_font_name="思源黑体CN"], .wbdCv-baseStyle[data_font_name="思源黑体CN"] * {
    font-family: 思源黑体CN;
}

.wbdCv-baseStyle[data-modal_margin='0'] .baseItem dl {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.wbdCv-baseStyle[data-modal_margin='0.2'] .baseItem dl {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
.wbdCv-baseStyle[data-modal_margin='0.4'] .baseItem dl {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.wbdCv-baseStyle[data-modal_margin='0.6'] .baseItem dl {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
}
.wbdCv-baseStyle[data-modal_margin='0.8'] .baseItem dl {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
}
.wbdCv-baseStyle[data-modal_margin='1'] .baseItem dl {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}
.wbdCv-baseStyle[data-modal_margin='1.2'] .baseItem dl {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
}

// .wbdCv-baseStyle[data-modal_pageMargin='0.5'] .wbdCv-baseTop, .wbdCv-baseStyle[data-modal_pageMargin='0.5'] .wbdCv-baseLeft, .wbdCv-baseStyle[data-modal_pageMargin='0.5'] .wbdCv-baseRight, .wbdCv-baseStyle[data-modal_pageMargin='0.5'] .wbdCv-baseBottom {
//     padding-left: 10px !important;
//     padding-right: 10px !important;
// }
// .wbdCv-baseStyle[data-modal_pageMargin='1'] .wbdCv-baseTop, .wbdCv-baseStyle[data-modal_pageMargin='1'] .wbdCv-baseLeft, .wbdCv-baseStyle[data-modal_pageMargin='1'] .wbdCv-baseRight, .wbdCv-baseStyle[data-modal_pageMargin='1'] .wbdCv-baseBottom {
//     padding-left: 30px !important;
//     padding-right: 30px !important;
// }

// .wbdCv-baseStyle[data-modal_pageMargin='1.5'] .wbdCv-baseTop, .wbdCv-baseStyle[data-modal_pageMargin='1.5'] .wbdCv-baseLeft, .wbdCv-baseStyle[data-modal_pageMargin='1.5'] .wbdCv-baseRight, .wbdCv-baseStyle[data-modal_pageMargin='1.5'] .wbdCv-baseBottom {
//     padding-left: 50px !important;
//     padding-right: 50px !important;
// }


@for $i from 12 through 18 {
    .resume-content-all[data_font_size='#{$i}'] .ql-editor, 
    .resume-content-all[data_font_size='#{$i}'] .list-top {
        font-size: #{$i}px !important;
    }
}

.resume-content-all[data-modal_margin='0'] .resume-content-main{
    margin: 0 !important;
}
.resume-content-all[data-modal_margin='0.2'] .resume-content-main {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
}
.resume-content-all[data-modal_margin='0.4'] .resume-content-main {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}
.resume-content-all[data-modal_margin='0.6'] .resume-content-main {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
}
.resume-content-all[data-modal_margin='0.8'] .resume-content-main {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
}
.resume-content-all[data-modal_margin='1'] .resume-content-main {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}
.resume-content-all[data-modal_margin='1.2'] .resume-content-main {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
}


.resume-content-all {

}

.resume-content-all[data-modal_pageMargin='0.5']{
    margin: 0 10px !important;
}
.resume-content-all[data-modal_pageMargin='1'] {
    margin: 0 30px !important;
}

.resume-content-all[data-modal_pageMargin='1.5'] {
    margin: 0 50px !important;
}

.resume-content-all[data_font_name="Aileron-Regular"] {
    font-family: Aileron-Regular;
}
.resume-content-all[data_font_name="Times New Roman"] {
    font-family: Times New Roman;
}
.resume-content-all[data_font_name="方正楷体简体"] {
    font-family: 方正楷体简体;
}
.resume-content-all[data_font_name="方正书宋简体"] {
    font-family: 方正书宋简体;
}
.resume-content-all[data_font_name="仿宋_GB2312"] {
    font-family: 仿宋_GB2312;
}
.resume-content-all[data_font_name="黑体"] {
    font-family: 黑体;
}
.resume-content-all[data_font_name="楷体"] {
    font-family: 楷体;
}
.resume-content-all[data_font_name="隶书"] {
    font-family: 隶书;
}
.resume-content-all[data_font_name="苹方黑体-简体"] {
    font-family: 苹方黑体-简体;
}
.resume-content-all[data_font_name="思源黑体CN"] {
    font-family: 思源黑体CN;
}


.resume-box .resume-content-all .photo-img .photo-box {
    border-radius: inherit;
    > div, > div > div, > div > div > div, > div > div > div > div{
        border-radius: inherit;
    }
}
.resume-box .resume-content-all .resume-l .photo-box {
    border-radius: inherit;
    > div, > div > div, > div > div > div, > div > div > div > div{
        border-radius: inherit;
    }
}

.resume-box .resume-content-all .resume-content .resume-content-main .basic-info-box {
    align-items: flex-start;
}