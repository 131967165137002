.pay-success-result {
    border-radius: 8px;
    position: relative;
    .icon {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 69px;
        height: 69px;
        img {
            width: 51px;
            height: 51px;
        }
    }
    h3 {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        // margin-top: 50px;
        padding-top: 35px;
        text-align: center;
        color: #00BCA6;
    }
    > p {
        margin-top: 24px;
        font-size: 15px;
        padding-left: 25px;
        margin-bottom: 5px;
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #ffffff;
        border-radius: 8px;
        padding: 24px 25px;
        padding-top: 0;

        .list {
            margin-bottom: 24px;
            .item {
                font-size: 16px;
            }
        }

        .qr-code {
            border: 1px solid #cdcdcd;
            width: 192px;
            height: 192px;
            margin-bottom: 25px;
            padding: 12px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .wx-icon {
        position: relative;
        top: 2px;
        left: 2px;
        display: inline-block;
        width: 24px;
        height: 24px;
        svg {
            width: 24px;
            height: 24px;
        }
    }
    .jz-resume-btn {
        width: 100%;
        font-size: 13px;
        background: #00BCA6;
        border-radius: 4px;
        color: #fff;
        text-align: center;
        padding: 9px 0;
    }
    .bind-wx {
        font-size: 20px;
        padding-top: 26px;
    }
    .bind-wx-p {
        font-weight: bold;
    }
}
