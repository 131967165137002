.layout-content {
  margin-bottom: 80px;
}
.router-list {
  position: fixed;
  max-width: 650px;
  margin: 0 auto;
  z-index: 100;
  bottom: -1px;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  background-color: #f7f7f7;
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.13);
  height: 80px;
  .router-item {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    align-items: center;
    .icon {
      width: 24px;
      height: 24px;
      svg {
        width: 24px;
        height: 24px;
      }
      margin-bottom: 2px;
    }
    .title {
      color: #999;
      font-size: 13px;
      height: 13px;
      line-height: 13px;
    }
  }
  .active {
    .title {
      color: #24C6B2;
    }
  }
}
