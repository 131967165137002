.center-modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 103;
  overflow: hidden;
  .center-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    width: 305px;
    min-height: 100px;
    border-radius: 12px;
    background-color: #fff;
  }
  .center-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
    .close-icon {
      width: 18px;
      height: 18px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
    z-index: 10;
  }
}



