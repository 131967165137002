.flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}
.flex-2 {
    flex: 2;
}
.flex-3 {
    flex: 3;
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}

.items-start {
    align-items: flex-start;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}

.close-scrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
}


html, body {
    font-family: PingFangSC, PingFang SC;
}

@for $i from 1 through 32 {
    @if $i <= 10 or $i % 2 == 0 {
        .p-#{$i} {
            padding: #{$i}px;
        }
    }
}
@for $i from 1 through 32 {
    @if $i <= 10 or $i % 2 == 0 {
        .px-#{$i} {
            padding: 0 #{$i}px;
        }
    }
}
@for $i from 1 through 32 {
    @if $i <= 10 or $i % 2 == 0 {
        .py-#{$i} {
            padding: #{$i}px 0;
        }
    }
}
@for $i from 1 through 32 {
    @if $i <= 10 or $i % 2 == 0 {
        .pt-#{$i} {
            padding-top: #{$i}px;
        }
    }
}
@for $i from 1 through 32 {
    @if $i <= 10 or $i % 2 == 0 {
        .pb-#{$i} {
            padding-bottom: #{$i}px;
        }
    }
}
@for $i from 1 through 32 {
    @if $i <= 10 or $i % 2 == 0 {
        .pl-#{$i} {
            padding-left: #{$i}px;
        }
    }
}
@for $i from 1 through 32 {
    @if $i <= 10 or $i % 2 == 0 {
        .pr-#{$i} {
            padding-right: #{$i}px;
        }
    }
}


@for $i from 1 through 32 {
    @if $i <= 10 or $i % 2 == 0 {
        .m-#{$i} {
            margin: #{$i}px;
        }
    }
}
@for $i from 1 through 40 {
    @if $i <= 10 or $i % 2 == 0 {
        .mx-#{$i} {
            margin: 0 #{$i}px;
        }
    }
}
@for $i from 1 through 40 {
    @if $i <= 10 or $i % 2 == 0 {
        .my-#{$i} {
            margin: #{$i}px 0;
        }
    }
}
@for $i from 1 through 32 {
    @if $i <= 10 or $i % 2 == 0 {
        .mt-#{$i} {
            margin-top: #{$i}px;
        }
    }
}
@for $i from 1 through 32 {
    @if $i <= 10 or $i % 2 == 0 {
        .mb-#{$i} {
            margin-bottom: #{$i}px;
        }
    }
}
@for $i from 1 through 32 {
    @if $i <= 10 or $i % 2 == 0 {
        .ml-#{$i} {
            margin-left: #{$i}px;
        }
    }
}
@for $i from 1 through 32 {
    @if $i <= 10 or $i % 2 == 0 {
        .mr-#{$i} {
            margin-right: #{$i}px;
        }
    }
}
