body, html {
    background-color: #FFFFFF !important;
}

.edit-custom-picker .adm-picker-header{
    a {
        color: #11BBA6;
    }
    a:first-child {
        color: #999999;
    }
}

:root {
    --adm-color-primary: #24C6B2;
}