

.page-chooseJob {
    .search-header {
        display: flex;
        align-items: center;
        padding: 12px;
        .icon {
            width: 22px;
            height: 22px;
            margin-right: 10px;
        }
        .input {
            flex: 1;
            height: 40px;
            line-height: 40px;
            span {
                font-size: 13px;
                color: #999999;
            }
        }
    }
    .content {
        background-color: #F7F8F9;
        height: calc(100vh - 62px);
        overflow: scroll;
        .title {
            font-size: 13px;
            color: #999999;
            margin-bottom: 16px;
            margin-top: 24px;
        }
        .jz-tag-list {
            display: flex;
            flex-wrap: wrap;
            .tag {
                outline: 1px solid #EBEBEB;
                box-sizing: border-box;
                white-space: nowrap;
                display: inline-block;
                padding: 0 10px;
                height: 32px;
                line-height: 32px;
                border-radius: 6px;
                font-size: 11px;
                color: #343542;
                text-align: center;
                margin-right: 8px;
                margin-bottom: 12px;
                padding: 0 16px;
                border: none;
            }
            .active {
                background: #24c6b2;
                color: #fff;
                outline: none;
                border: none;
            }
        }
    }
    .search-content{
        padding: 0 16px;
        background: #FFFFFF;
    }
    .content-p{
        white-space:pre-wrap;
        overflow-wrap: break-word;
        padding: 0 20px;
        font-size: 14px;

        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 17px;
        display: grid;
        .subtitle{
            font-weight: bold;
        }
    }
    
}

.choose-job{
    &-box{
        margin: 0 10px 10px;
        width: 100vw;
        padding: 16px;
        &:nth-child(1){
            margin:10px; 

        }
        height: 130px;
        background: #FFFFFF;
        border-radius: 8px;
        &-title{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #2C2C2C;
            margin-bottom: 14px;
        }
        &-subtitle{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
        }
        &-content{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            line-height: 24px;
            white-space:pre-wrap;
            overflow-wrap: break-word;
        }
    }
}


