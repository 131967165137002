.pay-modal-origin-price-window{
    position: absolute;
    top:274px;
    .word{
        padding-left: 12px;
        color: #1D2129;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        align-items: center;
        &>div:nth-child(1){
            padding-right: 10px;
        }
    }
    .red-word{
        padding-left:5px ;
        color: #EF333F;
        font-size: 14px;
    }
    .view-word{
        align-items: center;
        padding-left: 333px;
        &>div:nth-child(1){
            padding-right: 2px;
        }
        display: flex;
        color: #BF9790;
        cursor: pointer;
        &:hover{
            color: #987873;
        }
    }
}
.pay-modal-origin-price-modal{
    .ant-modal-content{
        padding: 20px 0px ;
    }
}