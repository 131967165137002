.wx-download {
    min-height: 100vh;
    background: #F7F7F7;
    padding: 18px;
    .card {
        background-color: #fff;
        border-radius: 8px;
        margin-bottom: 14px;
        padding: 14px;

        .h3 {
            font-size: 14px;
            font-weight: bold;
            display: flex;
            color: #2f2f2f;
            align-items: center;
            .svg-icon {
                width: 18px;
                height: 18px;
                margin-right: 7px;
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
            span {
                font-size: 11px;
                color: #A7ABB2;
                font-weight: normal;
            }
        }
        p {
            color: rgba(34,47,64,.35);
            margin-bottom: 22px;
            font-size: 13px;
        }
        .jz-resume-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-size: 13px;
            background: #fff;
            border: 1px solid #dfe0e3;
            color: #7a8087;
            font-weight: bold;
            text-align: center;
            height: 43px;
            line-height: 43px;
            border-radius: 7px;
        }
        .primary {
            background: #11BBA6;
            color: #fff;
            border: none;
        }
        input {
            border-radius: 7px;
            padding: 15px 12px;
            font-size: 13px;
            outline: none;
            border: 1px solid #EEEEEE;
            width: 100%;
            margin-bottom: 22px;
            color: #333;
        }
        input::placeholder {
            color: #A7ABB2;
        }
    }
}
