.iv-page{
    width: 100vw;
    height: calc(100vh - 80px);
    background: #F7F8F9;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;

    .iv-select{
        width: 100%;
        background: #ffffff;
        padding: 20px 14px;
        box-sizing: border-box;
        border-radius: 8px;

        &-title{
            color: #2C2C2C;
            font-size: 14px;
            font-weight: 500;
        }

        .iv-ant-select{
            width: 100%;
            margin-top: 12px;
            .ant-select-selector{
                border: none;
                background: #F7F7F8;
                height: 40px;
                line-height: 40px;
                .ant-select-selection-item{
                    line-height: 40px;
                }
            }
        }

        .svip-content{
            top: 17px;
            position: absolute;
            right: 16px;
            width: 45px;
            display: flex;
            align-items: center;
            padding: 0 11px 0 12px;
            height: 24px;
            background: linear-gradient( 90deg, #F7DFA1 0%, #F8D183 100%);
            border-radius: 4px;
            border: 1px solid #FFEABD;
            & div{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 12px;
                color: #7C582C;
            }
        }
        
    }

    .iv-description{
        border-radius: 8px;
        margin-top: 10px;
        width: 100%;
        background: #ffffff;
        padding: 20px 14px;
        box-sizing: border-box;
        position: relative;

        &-title{
            color: #2C2C2C;
            font-size: 14px;
            font-weight: 500;
        }

        .iv-ant-input{
            margin-top: 12px;
            background: #FAFAFA;
            border: none;
            min-height: 180px;
            max-height: 363px;
        }

        .tips{
            pointer-events: none;
            position: absolute;
            top: 76px;
            font-size: 12px;
            color: #C5C5C5;
            max-width: 300px;
            left: 34px;

            p{
                margin:4px 0;
            }
        }
    }

    .iv-button{
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 16px;
        width: 100%;
        height: 44px;
        background: #11BBA6;
        font-size: 14px;
        color: #ffffff;
        border-radius: 4px;
        margin-top: 20px;
        font-weight: 500;

        .ant-span{
            .ant-spin-dot-item{
                background: #ffffff;
            }
        }
    }
    .filter-blur{
        filter: blur(1px);
    }
}

.empty-page{
    background: url('https://oss.jianzeppt.com/sdkfklsdjfbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: relative;

    .empty-modal{
        width: 265px;
        height: 134px;
        background: #FFFFFF;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .content{
            margin-top: 26px;
            color: #353336;
            font-size: 14px;
            font-weight: 500;
            width: 161px;
            text-align: center;
        }
        .btn{
            margin-top: 13px;
            width: 124px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            background: #11BBA6;
            border-radius: 6px;
            color: #ffffff;
            font-weight: 500;
            font-size: 14px;
        }
    }
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled){
    background-color:#E5F2FF;
  }

  .mdl-title{
    display: flex;
    align-items: center;
    column-gap: 6px;

    .title{
        color: #000000;
        font-size: 16px;
        font-weight: 500;
    }
  }

  .mdl-text{
    text-align: center;
}
